<template>
    <b-overlay :show="isLoadingData || isSavingOwnerPromo">
        <b-card >
            <div class="head-btn">
                <b-button
                    :to="{ name: 'fives-vacations'}"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                >
                    <feather-icon icon="ArrowLeftIcon" style="color: white" />
                </b-button>
            </div>
            <div v-if="promoData">
                <ownerPromoForm :promoData="promoData" :isLoadingData="isSavingOwnerPromo" @save-promo="update" />
            </div>
            <b-alert show variant="danger" class="p-1 text-center" v-else>
                No se encontró información
            </b-alert>
        </b-card>

    </b-overlay>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showAlertMessage } from '@/helpers/helpers'
import ownerPromoForm from '@/modules/fivesClub/components/catalogs/ownersWebsite/ownerPromoForm'

export default {
    directives: {Ripple, },

    components: {
        ownerPromoForm
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('start', ['hotels','languages']),
    },
    async created() {
        await this.init()
    },
    data(){
        return {
            promoData: null,
            isLoadingData: false,
            isSavingOwnerPromo: false,
            file: null,
            imgUrl: process.env.VUE_APP_IMG_SRC_API,
        }
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ['getInitialContentForTheFivesVacations','fetchOwnerPromos','updateOwnerPromos']),
        ...mapMutations('start', ['setHotels', 'setLanguages']),
        ...mapMutations('fivesClubCatalogs', ['setSocialNetworks']),

        async init(){
            const idPromo = this.$route.params.id
            this.isLoadingData = true

            if( this.isANumber(idPromo) ){
                const { hotels, languages, socialNetworks } = await this.getInitialContentForTheFivesVacations()

                if( this.languages.length < 1) this.setLanguages(languages)
                if( this.hotels.length < 1) this.setHotels(hotels)
                this.setSocialNetworks(socialNetworks)

                this.promoData = await this.fetchOwnerPromos({idPromo})
            }

            this.isLoadingData = false
        },
        isANumber(string){
            return string ? !isNaN( string ) && string > 0 : false
        },
        async update(payload) {
            this.isSavingOwnerPromo = true;

            const response = await this.updateOwnerPromos(payload); // updateWebProp in backend
            if (response){
                const { status, message } = response
                if (status) {
                    showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
                    this.$router.push({ name: 'fives-vacations' })
                }
            }

            this.isSavingOwnerPromo = false;
        },
    },
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
</style>
